




































import { defineComponent, SetupContext } from '@vue/composition-api'

import { cardCheckProps, useCardCheck } from './CardCheck.hooks'
import { CardCheckProps } from './CardCheck.contracts'

/**
 * Component capable to render CardCheck element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const CardCheck = defineComponent({
  name: 'CardRadio',
  props: cardCheckProps,

  setup (props: CardCheckProps, ctx: SetupContext) {
    return useCardCheck(props, ctx)
  }
})
export default CardCheck
